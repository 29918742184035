.about {
    padding: 2rem;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
    text-align: center;
  }
  
  .about p {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin: 1rem auto;
  }
  
  .about-section {
    margin-bottom: 2rem;
  }
  
  .about-section h2 {
    margin-top: 0;
    color: #333;
    /* text-align: center;; */
  }
  
  .about-section p {
    font-size: 1rem;
    color: #666;
    margin: 0.5rem 0;
    line-height: 1.5;
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 1rem;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .about h1 {
      font-size: 2rem;
    }
  
    .about p {
      font-size: 1rem;
    }
  }
  