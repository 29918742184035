/* Ensure the wrapper takes full height of the viewport */
.app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: max-content;
    background-image: url('assets/images/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: bisque;
  }
  
  /* Flex-grow on main content to push the footer down */
  .main-content {
    flex: 1;
  }
  