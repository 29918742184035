.login-button {
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  color: black; /* White text */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
}

.login-button:hover {
  background-color: rgba(255, 255, 255, 0.062);
  box-shadow: 0 0 20px rgb(255, 155, 222), /* Outer shadow */
  0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
  inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
  transition: box-shadow .6s ease;
}
