.inbox {
    text-align: center;
  }

  .loading-text {
    text-align: center;
  }
  
  .inbox-header {
    background-color: #ffffff00;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    font-size: 1rem;
    color: rgb(0, 0, 0);
    padding: 2vh;
  }

  .inbox-header h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
  }
  
  .inbox-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 1rem auto;
  }

ul {
    list-style-type: none;
    padding: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .inbox-header li {
    background: #ffffff00;
    margin: 10px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), /* Outer shadow */
    0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
    inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
    text-align: left;
  }
  
  strong {
    display: block;
    margin-bottom: 5px;
  }