.services {
    padding: 2rem;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .services h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
    text-align: center;
  }
  
  .services p {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin: 1rem auto;
  }
  
  .service {
    margin-bottom: 2rem;
  }
  
  .service h2 {
    margin-top: 0;
    color: #333;
    /* text-align: center;; */
  }
  
  .service p {
    font-size: 1rem;
    color: #666;
    margin: 0.5rem 0;
    line-height: 1.5;
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 1rem;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .services h1 {
      font-size: 2rem;
    }
  
    .services p {
      font-size: 1rem;
    }
  
    .service h2 {
      font-size: 1.5rem;
    }
  
    .service p {
      font-size: 0.9rem;
    }
  }
  