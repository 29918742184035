.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 4rem;
    text-align: center;
    width: 100%;
    bottom: 0;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .footer-links li {
    display: inline;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  