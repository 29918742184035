.navbar {
  color: #fff;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: center; /* Center the navbar items */
  align-items: center;
}

.navbar-container {
  display: flex;
  justify-content: space-around; /* Space between links and toggle */
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 1rem;
}

.navbar-links a {
  color: #000000;
  text-decoration: none;
  padding: 0.5rem 1rem; /* Add padding for better click area */
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem 1rem; /* Add padding to match the navbar links */
}

.navbar-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #000000;
  margin: 4px 0;
  transition: 0.4s;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 5rem;
    /* right: 0; */
    background-color: #fffffff3;
    flex-direction: column;
    width: 90%;
    display: none;
    text-align: center; /* Center the mobile menu items */
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), /* Outer shadow */
    0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
    inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-toggle {
    display: flex;
    margin-left: auto; /* Push the hamburger menu to the right */
  }
}
