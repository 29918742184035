.contact {
    padding: 2rem;
    text-align: center;
  }
  
  .contact-banner {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 2rem;
  }
  
  .contact h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
  }
  
  .contact p {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 1rem auto;
  }
  
  .contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact .form-group {
    width: 100%;
    text-align: left;
  }
  
  .contact label {
    margin-bottom: 0.5rem;
  }
  
  .contact input,
  .contact textarea {
    background-color: rgba(255, 197, 236, 0);
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .contact textarea {
    width: 100%; /* Make the textarea take the full width of its container */
    min-height: 200px; /* Minimum height to ensure it's vertically longer */
    height: auto; /* Allows the height to adjust automatically */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    padding: 10px; /* Add some padding for better readability */
    resize: vertical; /* Allow users to resize the textarea vertically only */
    font-family: Arial, sans-serif; /* Set a default font */
    font-size: 16px; /* Set a default font size */
  }
  
  
  .contact button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }
  
  .contact button:hover {
    background-color: #555;
  }
  
  @media (max-width: 768px) {
    .contact h1 {
      font-size: 2rem;
    }
  
    .contact p {
      font-size: 1rem;
    }

    textarea {
      min-height: 150px; /* Adjust minimum height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    textarea {
      min-height: 100px; /* Further adjust minimum height for very small screens */
    }
  }