.portfolio {
    padding: 2rem;
    text-align: center;
  }
  
  .portfolio h1 {
    font-size: 2.5rem;
    margin-top: 0;
    color: #333;
  }
  
  .portfolio p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 1rem auto;
  }
  
  .portfolio-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .portfolio-item {
    /* background-color: rgba(255, 197, 236, 0.26);
    border: solid rgb(255, 155, 222) 1px; */
    padding: 2rem;
    border-radius: 15px;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1),
    0 0 30px rgba(0, 0, 0, 0.05), 
    inset 0 0 10px rgba(0, 0, 0, 0.05);  */
    max-width: 300px;
    text-align: left;
  }

  .portfolio-item:hover {
    background-color: rgba(255, 255, 255, 0.062);
    box-shadow: 0 0 20px rgb(255, 155, 222), /* Outer shadow */
    0 0 30px rgba(0, 0, 0, 0.05), /* Outer shadow */
    inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow */
    transition: box-shadow .6s ease;
  }
  
  .portfolio-item img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  .portfolio-item h2 {
    font-size: 1.5rem;
    margin-top: 0;
    color: #333;
  }
  
  .portfolio-item p {
    font-size: 1rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .portfolio h1 {
      font-size: 2rem;
    }
  
    .portfolio p {
      font-size: 1rem;
    }
  
    .portfolio-item h2 {
      font-size: 1.2rem;
    }
  
    .portfolio-item p {
      font-size: 0.9rem;
    }
  }
  