.privacy-policy {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .policy-header {
    text-align: center;
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .privacy-policy h2 {
    font-size: 1rem;
    color: #333;
    margin-top: 1rem;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin: 1rem 0;
  }
  