.home {
    padding: 2rem;
    text-align: center;
  }
  
  .home-banner {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 5px;
    margin: 2rem auto; 
  }
  
  .home h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
  }
  
  .home p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 1rem auto;
  }
  
  .home-about p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }

  .home-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: min(5rem, 40px);
  }
  
  .home-services .service {
    background-color: rgba(255, 255, 255, 0);
    border: #ffffff00;
    padding: 2rem;
    border-radius: 15px;
    max-width: 300px;
    text-align: center;
    margin-bottom: 0;
  }

  .home-services .service:hover {
    /* background-color: rgba(255, 155, 222, 0.137); */
    padding: 2rem;
    /* border: solid rgb(255, 155, 222) 1px; */
    max-width: 300px;
    text-align: center;
    /* transition: background-color .6s ease;  */
  }
  
  .home-services .service img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  .home-services .service h2 {
    font-size: 1.5rem;
    margin-top: 0;
    color: #333;
  }
  
  .home-services .service p {
    font-size: 1rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .home h1 {
      font-size: 2rem;
    }
  
    .home p {
      font-size: 1rem;
    }
  
    .service h3 {
      font-size: 1.2rem;
    }
  
    .service p {
      font-size: 0.9rem;
    }
  
    .home-about p {
      font-size: 1rem;
    }
  }
  